import { once } from 'lodash';
import { createNoopController } from '../../viewer-script/controllers/createNoopController';
import { validateLanguage } from '../../common/services/validate-language';
import {
  createLogger,
  getEnvironment,
  initializeNavigationHandlerForEditor,
  doRouting,
  createControllerId,
} from '../../common/controller/helpers';
import { getSectionUrl } from '../services/get-section-url';
import { BLOG_SECTION_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { createReduxStore } from './create-redux-store';
import reducers from '../reducers';
import { createRouter } from './create-router';
import { handleProvisioning } from '../../common/services/handle-provisioning';
import { handleError } from '../../common/store/debug-state/handle-error';
import {
  initializeActions,
  initializePromisifiedActions,
  initializeStoreBaseData,
  refreshDataOnLogin,
} from './init-actions';
import { FEED_WIDGET_NAME } from '../../viewer-script/constants/widgets';
import { getPathname } from '../../common/store/location/get-pathname';
import { setRouterMatch } from '../../common/router';
import { createMonitoring } from '../../common/services/create-monitoring';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { simulateControllerError } from '../../common/services/simulate-error';
import { isSite } from '../../common/store/basic-params/basic-params-selectors';
import { isInWorker } from '../../common/services/is-in-worker';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import listenToEditModeChange from '../../common/services/listen-to-edit-mode-change';
import listenToSettingsChange from '../../common/services/listen-to-settings-change';
import { getInitialStateVersions } from '../../common/services/state-optimizer/change-detector';

const isProduction = process.env.NODE_ENV === 'production';
let isControllerInitialized = false;

export const createFeedPageController = (
  { appParams, compId, config, setProps, wixCodeApi, platformAPIs, type },
  allCtrls
) => {
  const { isEditor, isPreview, isSSR, isDebug } = getEnvironment(wixCodeApi);

  // there is a bug in viewerScriptWrapper where it initialized controller again when going into preview
  if ((isEditor || isPreview) && (isControllerInitialized || isInWorker())) {
    return createNoopController();
  } else {
    isControllerInitialized = true;
  }

  const { sentry, captureToSentry, fedopsLogger } = createMonitoring({
    origin: `app-worker-${FEED_WIDGET_NAME}`,
    platformAPIs,
    appParams,
    isSSR,
    isDebug,
    type,
  });

  fedopsLogger.appLoadStarted();
  const fedopsAppLoaded = once(() => fedopsLogger.appLoaded());

  const language = validateLanguage(wixCodeApi.site.language);

  const log = createLogger(isDebug, isProduction);

  log('createFeedPageController', { appParams, allCtrls, wixCodeApi, isSSR, language });

  const pageReady = async () => {
    let store;
    try {
      log('createFeedPageController.pageReady -> start');

      const sectionUrl = await getSectionUrl({
        wixCodeApi,
        instanceId: appParams.instanceId,
        sectionId: BLOG_SECTION_ID,
      });

      simulateControllerError(wixCodeApi, 'feed-page.pageReady');

      // eslint-disable-next-line
      let router;
      const getRouter = () => router;

      const getSectionPathname = store =>
        isSite(store.getState())
          ? getPathname(wixCodeApi.location, sectionUrl)
          : '/' + wixCodeApi.location.path.join('/');

      store = createReduxStore({
        appParams,
        wixCodeApi,
        compId,
        reducers,
        fedopsLogger,
        getRouter,
        platformAPIs,
        isSSR,
        sentry,
        isEditor,
        isPreview,
      });
      const actions = initializeActions({
        wixCodeApi,
        store,
        fedopsLogger,
        fedopsAppLoaded,
      });
      const actionsPromisified = initializePromisifiedActions({ wixCodeApi, compId, store });

      await initializeStoreBaseData({
        wixCodeApi,
        store,
        language,
        platformAPIs,
        config,
        sectionUrl,
        bundleName: 'blog',
        fedopsAppName: 'communities-blog-app',
        translationsName: 'main',
      });

      router = createRouter(store, config, wixCodeApi, compId);

      if (wixCodeApi.location.onChange) {
        wixCodeApi.location.onChange(({ path }) => {
          log('navigated to new path', path);
          doRouting({
            store,
            router,
            pathname: isSite(store.getState()) ? getSectionPathname(store) : '/' + path.join('/'),
            isInitialLoad: false,
          });
        });
      }

      await doRouting({
        store,
        router,
        pathname: getSectionPathname(store),
        isInitialLoad: true,
      });

      log('createFeedPageController.pageReady -> done');
      const state = store.getState();

      // after initial routing is done, we subscribe to get routing change as fast as route changes, without waiting for handler to resolve
      router.onMatch(match => store.dispatch(setRouterMatch(match)));

      const stateVersions = getInitialStateVersions(state);
      const controllerId = createControllerId();

      setProps({
        state,
        stateVersions,
        actions,
        actionsPromisified,
        cssBaseUrl: appParams.baseUrls.staticsBaseUrl,
        isSSR,
        isRTL: isRtlLanguage(language),
        controllerId,
      });

      if (isSSR) {
        fedopsAppLoaded();
      }

      if (isEditor || isPreview) {
        listenToEditModeChange(store);
        listenToSettingsChange(store);
        initializeNavigationHandlerForEditor({ store, router });
      }

      refreshDataOnLogin({ wixCodeApi, store, router, pathname: getSectionPathname(store), doRouting });
      subscribeToChange(store, stateVersions, setProps, controllerId);
    } catch (error) {
      handleError({ controller: FEED_WIDGET_NAME, store, setProps, appParams, captureToSentry, isDebug, isSSR })(error);
    }
  };

  return Promise.resolve({
    pageReady: () =>
      isEditor ? handleProvisioning(appParams, fedopsLogger, wixCodeApi, setProps, pageReady) : pageReady(),
    exports: () => {},
  }).catch(console.error);
};
